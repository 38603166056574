<script setup>
import { reactive, nextTick, ref } from "vue";
import { maska as vMaska } from "maska";
import axios from "axios";

const state = reactive({
  firstName: "",
  firstNameErrorMessage: "",
  lastName: "",
  lastNameErrorMessage: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  email: "",
  emailErrorMessage: "",
  phone: "",
  phoneErrorMessage: "",
  authorizedAgent: "",
  agentFirstName: "",
  agentFirstNameErrorMessage: "",
  agentLastName: "",
  agentLastNameErrorMessage: "",
  agentPhone: "",
  agentPhoneErrorMessage: "",
  agentEmail: "",
  agentEmailErrorMessage: "",
  receiveInformationOnlineAccount: false,
  receiveInformationEmail: false,
  receiveInformationMail: false,
  optOutOfSaleOrSharingOfInformation: false,
  requestForInformation: false,
  requestToDeleteInformation: false,
  requestToCorrectInformation: false,
  submitAnAppeal: false,
  agreeTerms: false,
  agreeTermsErrorMessage: "",
  agreeTermsAgent: false,
  agreeTermsAgentErrorMessage: "",
  isLoading: false,
  dataSent: false,
  thankYouComponentHeight: "",
});

const formContainer = ref(null);

const fields = [
  {
    name: "firstName",
    validation: (value) => {
      if (!!value) {
        return true;
      } else {
        state.firstNameErrorMessage = "First name is required";
        return false;
      }
    },
  },
  {
    name: "lastName",
    validation: (value) => {
      if (!!value) {
        return true;
      } else {
        state.lastNameErrorMessage = "Last name is required";
        return false;
      }
    },
  },
  {
    name: "email",
    validation: (value) => {
      if (!value) {
        state.emailErrorMessage = "Email is required";
        return false;
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        state.emailErrorMessage = "Valid email is required";
        return false;
      }
      return true;
    },
  },
  {
    name: "phone",
    validation: (value) => {
      if (!value) {
        state.phoneErrorMessage = "Phone number is required";
        return false;
      } else if (value.length < 14) {
        state.phoneErrorMessage = "Phone number is incomplete";
        return false;
      } else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
        state.phoneErrorMessage = "Valid phone number is required";
        return false;
      }
      return true;
    },
  },
  {
    name: "agentFirstName",
    validation: (value) => {
      if (!value && state.authorizedAgent === "yes") {
        state.agentFirstNameErrorMessage = "Agent first name is required";
        return false;
      }
      return true;
    },
  },
  {
    name: "agentLastName",
    validation: (value) => {
      if (!value && state.authorizedAgent === "yes") {
        state.agentLastNameErrorMessage = "Agent last name is required";
        return false;
      }
      return true;
    },
  },
  {
    name: "agentEmail",
    validation: (value) => {
      if (!value && state.authorizedAgent === "yes") {
        state.agentEmailErrorMessage = "Email is required";
        return false;
      } else if (
        !/\S+@\S+\.\S+/.test(value) &&
        state.authorizedAgent === "yes"
      ) {
        state.agentEmailErrorMessage = "Valid email is required";
        return false;
      }
      return true;
    },
  },
  {
    name: "agentPhone",
    validation: (value) => {
      if (!value && state.authorizedAgent === "yes") {
        state.agentPhoneErrorMessage = "Agent phone number is required";
        return false;
      } else if (value.length < 14 && state.authorizedAgent === "yes") {
        state.agentPhoneErrorMessage = "Agent phone number is incomplete";
        return false;
      } else if (
        !/^\(\d{3}\) \d{3}-\d{4}$/.test(value) &&
        state.authorizedAgent === "yes"
      ) {
        state.agentPhoneErrorMessage = "Valid agent phone number is required";
        return false;
      }
      return true;
    },
  },
  {
    name: "agreeTerms",
    validation: (value) => {
      if (!value) {
        state.agreeTermsErrorMessage = "Terms are not checked";
      }
      return value;
    },
  },
  {
    name: "agreeTermsAgent",
    validation: (value) => {
      if (!value && state.authorizedAgent === "yes") {
        state.agreeTermsAgentErrorMessage = "Agent Terms are not checked";
        return false;
      }
      return true;
    },
  },
];

const removeErrorMessage = (fieldName) => {
  state[`${fieldName}ErrorMessage`] = "";
};

const isEmpty = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  return !value && value !== false && value !== 0;
};

const sendFormData = async () => {
  state.isLoading = true;
  const validationResults = fields.map((field) =>
    field.validation(state[field.name]),
  );

  const isValid = validationResults.every((result) => result);
  if (!isValid) {
    state.isLoading = false;
    await nextTick();
    // Query the DOM for the first element with the 'error' class
    const firstErrorElement = document.querySelector(".error");
    if (firstErrorElement) {
      const rect = firstErrorElement.getBoundingClientRect();
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const offsetPosition = rect.top + scrollTop - 100; // 80px for your navbar
      window.scrollTo({ top: offsetPosition, behavior: "smooth" });
    }
    console.log("Error!");
  } else {
    const formData = {
      firstName: state.firstName,
      lastName: state.lastName,
      address: state.address,
      city: state.city,
      state: state.state,
      zip: state.zip,
      email: state.email,
      phone: state.phone,
      authorizedAgent: state.authorizedAgent === "yes" ? "yes": "no",
      agentFirstName: state.agentFirstName,
      agentLastName: state.agentLastName,
      agentPhone: state.agentPhone,
      agentEmail: state.agentEmail,
      receiveInformationOnlineAccount: state.receiveInformationOnlineAccount
        ? "yes"
        : "no",
      receiveInformationEmail: state.receiveInformationEmail ? "yes" : "no",
      receiveInformationMail: state.receiveInformationMail ? "yes" : "no",
      optOutOfSaleOrSharingOfInformation:
        state.optOutOfSaleOrSharingOfInformation ? "yes" : "no",
      requestForInformation: state.requestForInformation ? "yes" : "no",
      requestToDeleteInformation: state.requestToDeleteInformation
        ? "yes"
        : "no",
      requestToCorrectInformation: state.requestToCorrectInformation
        ? "yes"
        : "no",
      submitAnAppeal: state.submitAnAppeal ? "yes" : "no",
      agreeTerms: state.agreeTerms ? "yes" : "",
      agreeTermsAgent: state.agreeTermsAgent ? "yes" : "",
      project: "OpenHome"
    };

    const cleanedObj = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => !isEmpty(value)),
    );

    try {
      const url = "https://code.inbounds.com/slack/send-message";
      await axios.post(url, {
        formData: cleanedObj,
        type: "do-not-sell-information-form",
      });

      // If successful, set dataSent to true
      state.dataSent = true;
    } catch (error) {
      console.error("There was an error sending the form data", error);
      // Handle the error appropriately, e.g., show an error message
    }

    state.thankYouComponentHeight = formContainer.value.clientHeight;
    state.isLoading = false;
    state.dataSent = true;
    await nextTick();

    const dataSent = document.querySelector(".thank-you-message");
    if (dataSent) {
      const rect = dataSent.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      // Calculate the distance from the top of the document to the middle of the element
      const elementCenter = rect.top + scrollTop + rect.height / 2;

      // Calculate the desired scroll position so that the element is in the middle of the viewport
      const viewportHeight = window.innerHeight;
      const desiredScrollTop = elementCenter - viewportHeight / 2;

      window.scrollTo({ top: desiredScrollTop, behavior: "smooth" });
    }

    console.log("Sent!");
  }
};
</script>

<template>
  <div class="container">
    <div v-if="!state.dataSent" ref="formContainer" class="form-container">
      <div v-if="state.isLoading" class="loading-wrapper">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="row">
        <h1>Opt-Out Request</h1>
      </div>
      <div class="row">
        <p>
          If you are a resident of California, Connecticut, or Virginia you may
          have additional rights to access and control your Personal
          Information, including the right to instruct us not to sell or share
          (for targeted or cross-context behavioral advertising) your personal
          information. To submit a request, please provide the information in
          the fields below and select Submit. Exemptions may apply.
        </p>
      </div>
      <div class="row">
        <div class="input-box">
          <label
            :class="{ 'error-label': state.firstNameErrorMessage }"
            for="firstName"
            >First Name</label
          >
          <input
            v-model="state.firstName"
            @input="removeErrorMessage('firstName')"
            :class="{ error: state.firstNameErrorMessage }"
            :disabled="state.isLoading"
            type="text"
            id="firstName"
          />
          <p class="error-msg">{{ state.firstNameErrorMessage }}</p>
        </div>
        <div class="input-box">
          <label
            :class="{ 'error-label': state.lastNameErrorMessage }"
            for="lastName"
            >Last Name</label
          >
          <input
            v-model="state.lastName"
            @input="removeErrorMessage('lastName')"
            :class="{ error: state.lastNameErrorMessage }"
            :disabled="state.isLoading"
            type="text"
            id="lastName"
          />
          <p class="error-msg">{{ state.lastNameErrorMessage }}</p>
        </div>
      </div>
      <div class="row">
        <div class="input-box">
          <label for="address">Address</label>
          <input
            v-model="state.address"
            :disabled="state.isLoading"
            type="text"
            id="address"
          />
        </div>
        <div class="input-box">
          <label for="city">City</label>
          <input
            v-model="state.city"
            :disabled="state.isLoading"
            type="text"
            id="city"
          />
        </div>
      </div>
      <div class="row">
        <div class="input-box">
          <label for="state">State</label>
          <select
            v-model="state.state"
            :disabled="state.isLoading"
            id="state"
            name="state"
          >
            <option selected disabled="" value="">Please Select</option>
            <option value="Alabama">Alabama</option>
            <option value="Alaska">Alaska</option>
            <option value="Arizona">Arizona</option>
            <option value="Arkansas">Arkansas</option>
            <option value="California">California</option>
            <option value="Colorado">Colorado</option>
            <option value="Connecticut">Connecticut</option>
            <option value="Delaware">Delaware</option>
            <option value="Florida">Florida</option>
            <option value="Georgia">Georgia</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Idaho">Idaho</option>
            <option value="Illinois">Illinois</option>
            <option value="Indiana">Indiana</option>
            <option value="Iowa">Iowa</option>
            <option value="Kansas">Kansas</option>
            <option value="Kentucky">Kentucky</option>
            <option value="Louisiana">Louisiana</option>
            <option value="Maine">Maine</option>
            <option value="Maryland">Maryland</option>
            <option value="Massachusetts">Massachusetts</option>
            <option value="Michigan">Michigan</option>
            <option value="Minnesota">Minnesota</option>
            <option value="Mississippi">Mississippi</option>
            <option value="Missouri">Missouri</option>
            <option value="Montana">Montana</option>
            <option value="Nebraska">Nebraska</option>
            <option value="Nevada">Nevada</option>
            <option value="New Hampshire">New Hampshire</option>
            <option value="New Jersey">New Jersey</option>
            <option value="New Mexico">New Mexico</option>
            <option value="New York">New York</option>
            <option value="North Carolina">North Carolina</option>
            <option value="North Dakota">North Dakota</option>
            <option value="Ohio">Ohio</option>
            <option value="Oklahoma">Oklahoma</option>
            <option value="Oregon">Oregon</option>
            <option value="Pennsylvania">Pennsylvania</option>
            <option value="Rhode Island">Rhode Island</option>
            <option value="South Carolina">South Carolina</option>
            <option value="South Dakota">South Dakota</option>
            <option value="Tennessee">Tennessee</option>
            <option value="Texas">Texas</option>
            <option value="Utah">Utah</option>
            <option value="Vermont">Vermont</option>
            <option value="Virginia">Virginia</option>
            <option value="Washington">Washington</option>
            <option value="West Virginia">West Virginia</option>
            <option value="Wisconsin">Wisconsin</option>
            <option value="Wyoming">Wyoming</option>
            <option value="District of Columbia">District of Columbia</option>
            <option value="Guam">Guam</option>
            <option value="Northern Mariana Islands">
              Northern Mariana Islands
            </option>
            <option value="Puerto Rico">Puerto Rico</option>
            <option value="Virgin Islands">Virgin Islands</option>
            <option value="Quebec">Quebec</option>
            <option value="British Columbia">British Columbia</option>
            <option value="Alberta">Alberta</option>
            <option value="Ontario">Ontario</option>
          </select>
        </div>
        <div class="input-box">
          <label for="zip">Zip Code</label>
          <input
            v-model="state.zip"
            :disabled="state.isLoading"
            v-maska="'#####'"
            type="text"
            id="zip"
          />
        </div>
      </div>
      <div class="row">
        <div class="input-box">
          <label :class="{ 'error-label': state.emailErrorMessage }" for="email"
            >Email</label
          >
          <input
            v-model="state.email"
            @input="removeErrorMessage('email')"
            :class="{ error: state.emailErrorMessage }"
            :disabled="state.isLoading"
            type="text"
            id="email"
          />
          <p class="error-msg">{{ state.emailErrorMessage }}</p>
        </div>
        <div class="input-box">
          <label :class="{ 'error-label': state.phoneErrorMessage }" for="phone"
            >Phone Number</label
          >
          <input
            v-model="state.phone"
            @input="removeErrorMessage('phone')"
            :class="{ error: state.phoneErrorMessage }"
            :disabled="state.isLoading"
            v-maska="'(###) ###-####'"
            type="text"
            id="phone"
          />
          <p class="error-msg">{{ state.phoneErrorMessage }}</p>
        </div>
      </div>
      <div class="row column">
        <p>
          Are you submitting this request (as an authorized agent) on behalf of
          someone else?
        </p>
        <div class="check-box-container">
          <div class="check-box">
            <input
              v-model="state.authorizedAgent"
              :disabled="state.isLoading"
              type="radio"
              id="agent-yes"
              value="yes"
            />
            <label for="agent-yes">Yes</label>
          </div>
          <div class="check-box">
            <input
              v-model="state.authorizedAgent"
              :disabled="state.isLoading"
              type="radio"
              id="agent-no"
              value="no"
            />
            <label for="agent-no">No</label>
          </div>
        </div>
      </div>
      <div v-if="state.authorizedAgent === 'yes'" class="row">
        <div class="input-box">
          <label
            :class="{ 'error-label': state.agentFirstNameErrorMessage }"
            for="agentFirstName"
            >Agent First Name</label
          >
          <input
            v-model="state.agentFirstName"
            @input="removeErrorMessage('agentFirstName')"
            :disabled="state.isLoading"
            :class="{ error: state.agentFirstNameErrorMessage }"
            type="text"
            id="agentFirstName"
          />
          <p class="error-msg">{{ state.agentFirstNameErrorMessage }}</p>
        </div>
        <div class="input-box">
          <label
            :class="{ 'error-label': state.agentLastNameErrorMessage }"
            for="agentLastName"
            >Agent Last Name</label
          >
          <input
            v-model="state.agentLastName"
            @input="removeErrorMessage('agentLastName')"
            :disabled="state.isLoading"
            :class="{ error: state.agentLastNameErrorMessage }"
            type="text"
            id="agentLastName"
          />
          <p class="error-msg">{{ state.agentLastNameErrorMessage }}</p>
        </div>
      </div>
      <div v-if="state.authorizedAgent === 'yes'" class="row">
        <div class="input-box">
          <label
            :class="{ 'error-label': state.agentEmailErrorMessage }"
            for="agentEmail"
            >Agent Email Address</label
          >
          <input
            v-model="state.agentEmail"
            @input="removeErrorMessage('agentEmail')"
            :class="{ error: state.agentEmailErrorMessage }"
            :disabled="state.isLoading"
            type="text"
            id="agentEmail"
          />
          <p class="error-msg">{{ state.agentEmailErrorMessage }}</p>
        </div>
        <div class="input-box">
          <label
            :class="{ 'error-label': state.agentPhoneErrorMessage }"
            for="agentPhone"
            >Agent Phone Number</label
          >
          <input
            v-maska="'(###) ###-####'"
            v-model="state.agentPhone"
            :disabled="state.isLoading"
            @input="removeErrorMessage('agentPhone')"
            :class="{ error: state.agentPhoneErrorMessage }"
            type="text"
            id="agentPhone"
          />
          <p class="error-msg">{{ state.agentPhoneErrorMessage }}</p>
        </div>
      </div>
      <div class="row column">
        <p>How do you want to receive the information you requested?</p>
        <div class="check-box-container">
          <div class="check-box">
            <input
              v-model="state.receiveInformationOnlineAccount"
              :disabled="state.isLoading"
              type="checkbox"
              id="information-online"
              value="true"
            />
            <label for="information-online">Online Account</label>
          </div>
          <div class="check-box">
            <input
              v-model="state.receiveInformationEmail"
              :disabled="state.isLoading"
              type="checkbox"
              id="information-email"
              value="true"
            />
            <label for="information-email">Email</label>
          </div>
          <div class="check-box">
            <input
              v-model="state.receiveInformationMail"
              :disabled="state.isLoading"
              type="checkbox"
              id="information-mail"
              value="true"
            />
            <label for="information-mail">Mail</label>
          </div>
        </div>
      </div>
      <div class="row column">
        <p>What request are you making?</p>
        <div class="check-box-container">
          <div class="check-box">
            <input
              v-model="state.optOutOfSaleOrSharingOfInformation"
              :disabled="state.isLoading"
              type="checkbox"
              id="request-opt-out"
              value="true"
            />
            <label for="request-opt-out"
              >Opt-out of sale or sharing of information</label
            >
          </div>
          <div class="check-box">
            <input
              v-model="state.requestForInformation"
              :disabled="state.isLoading"
              type="checkbox"
              id="request-information"
              value="true"
            />
            <label for="request-information">Request for information</label>
          </div>
          <div class="check-box">
            <input
              v-model="state.requestToDeleteInformation"
              :disabled="state.isLoading"
              type="checkbox"
              id="request-delete-information"
              value="true"
            />
            <label for="request-delete-information"
              >Request to delete information</label
            >
          </div>
          <div class="check-box">
            <input
              v-model="state.requestToCorrectInformation"
              :disabled="state.isLoading"
              type="checkbox"
              id="request-correct-information"
              value="true"
            />
            <label for="request-correct-information"
              >Request to correct information</label
            >
          </div>
          <div class="check-box">
            <input
              v-model="state.submitAnAppeal"
              :disabled="state.isLoading"
              type="checkbox"
              id="request-appeal"
              value="true"
            />
            <label for="request-appeal">Submit an appeal</label>
          </div>
        </div>
      </div>
      <div class="row">
        <p :class="{ error: state.agreeTermsErrorMessage }">
          <span class="inline-check-container">
            <input
              v-model="state.agreeTerms"
              @change="removeErrorMessage('agreeTerms')"
              :class="{ error: state.agreeTermsErrorMessage }"
              :disabled="state.isLoading"
              type="checkbox"
              id="accurate-information"
              value="true"
            />
            <label for="accurate-information">Agree.</label></span
          >
          By checking this box, I give my electronic signature and declare under
          penalty of perjury under the laws of the state I reside in that I am
          submitting this form on my own behalf and the information provided is
          accurate.
        </p>
      </div>
      <div v-if="state.authorizedAgent === 'yes'" class="row">
        <p :class="{ error: state.agreeTermsAgentErrorMessage }">
          <span class="inline-check-container">
            <input
              v-model="state.agreeTermsAgent"
              @change="removeErrorMessage('agreeTermsAgent')"
              :class="{ error: state.agreeTermsAgentErrorMessage }"
              :disabled="state.isLoading"
              type="checkbox"
              id="accurate-information-agent"
              value="true"
            />
            <label for="accurate-information-agent">Agree.</label></span
          >
          By checking this box, I give my electronic signature and declare under
          penalty of perjury under the laws of the state I reside in that I am
          the above-named authorized agent, that I have been duly authorized by
          the above-named consumer to submit this form on their behalf, and the
          information provided is accurate.
        </p>
      </div>
      <div class="row center">
        <button @click="sendFormData()">
          {{ state.isLoading ? "Loading..." : "Submit" }}
        </button>
      </div>
    </div>
    <div
      v-else
      :style="{ 'min-height': state.thankYouComponentHeight + 'px' }"
      class="thank-you-message"
    >
      <div class="box">
        <svg
          width="800px"
          height="800px"
          viewBox="0 -3 32 32"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
        >
          <title>checkmark</title>
          <desc>Created with Sketch Beta.</desc>
          <defs></defs>
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            sketch:type="MSPage"
          >
            <g
              id="Icon-Set"
              sketch:type="MSLayerGroup"
              transform="translate(-516.000000, -1037.000000)"
              fill="#000000"
            >
              <path
                d="M545.34,1042.62 C545.34,1042.62 528.282,1060.01 528.014,1060.29 C527.216,1061.1 525.924,1061.1 525.126,1060.29 C525.126,1060.29 518.588,1053.62 518.568,1053.6 C517.832,1052.78 517.852,1051.51 518.629,1050.71 C519.426,1049.9 520.719,1049.9 521.517,1050.71 L526.569,1055.87 L542.452,1039.67 C543.249,1038.86 544.542,1038.86 545.34,1039.67 C546.137,1040.48 546.137,1041.8 545.34,1042.62 L545.34,1042.62 Z M546.783,1038.2 C545.188,1036.57 542.603,1036.57 541.008,1038.2 L526.569,1052.92 L522.96,1049.24 C521.365,1047.62 518.779,1047.62 517.185,1049.24 C515.59,1050.87 515.59,1053.51 517.185,1055.13 L523.682,1061.76 C525.277,1063.39 527.862,1063.39 529.457,1061.76 L546.783,1044.09 C548.378,1042.46 548.378,1039.82 546.783,1038.2 L546.783,1038.2 Z"
                id="checkmark"
                sketch:type="MSShapeGroup"
              ></path>
            </g>
          </g>
        </svg>
      </div>
      <div class="box">
        <h1>Thank you for submitting your info.</h1>
        <h1>We will contact you shortly.</h1>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// Variables
$primary-color: #c81a28;
$secondary-color: #414141;
$font-stack: Arial, sans-serif;

.main-container .affiliate-content .container .container {
  box-shadow: none;
  margin: 0;
}

.container {
  max-width: 800px;
  margin: auto;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  box-shadow: none;
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    position: relative;
    .loading-wrapper {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
      background: #ffffff99;
      display: flex;
      align-items: center;
      justify-content: center;
      .lds-roller {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }
      .lds-roller div {
        animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 40px 40px;
      }
      .lds-roller div:after {
        content: " ";
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: $primary-color;
        margin: -4px 0 0 -4px;
      }
      .lds-roller div:nth-child(1) {
        animation-delay: -0.036s;
      }
      .lds-roller div:nth-child(1):after {
        top: 63px;
        left: 63px;
      }
      .lds-roller div:nth-child(2) {
        animation-delay: -0.072s;
      }
      .lds-roller div:nth-child(2):after {
        top: 68px;
        left: 56px;
      }
      .lds-roller div:nth-child(3) {
        animation-delay: -0.108s;
      }
      .lds-roller div:nth-child(3):after {
        top: 71px;
        left: 48px;
      }
      .lds-roller div:nth-child(4) {
        animation-delay: -0.144s;
      }
      .lds-roller div:nth-child(4):after {
        top: 72px;
        left: 40px;
      }
      .lds-roller div:nth-child(5) {
        animation-delay: -0.18s;
      }
      .lds-roller div:nth-child(5):after {
        top: 71px;
        left: 32px;
      }
      .lds-roller div:nth-child(6) {
        animation-delay: -0.216s;
      }
      .lds-roller div:nth-child(6):after {
        top: 68px;
        left: 24px;
      }
      .lds-roller div:nth-child(7) {
        animation-delay: -0.252s;
      }
      .lds-roller div:nth-child(7):after {
        top: 63px;
        left: 17px;
      }
      .lds-roller div:nth-child(8) {
        animation-delay: -0.288s;
      }
      .lds-roller div:nth-child(8):after {
        top: 56px;
        left: 12px;
      }
      @keyframes lds-roller {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
  .thank-you-message {
    display: flex;
    align-items: center;
    gap: 20px;
    .box {
      display: flex;
      flex-direction: column;
      svg {
        max-height: 80px;
        max-width: 80px;
        path {
          fill: $primary-color;
        }
      }
      h1 {
        color: $secondary-color;
      }
    }
  }
  .row {
    width: 100%;
    font-family: $font-stack;
    display: flex;
    gap: 20px;
    &.column {
      gap: 10px;
      flex-direction: column;
    }
    &.center {
      justify-content: center;
    }
    h1 {
      font-family: inherit;
      color: $primary-color;
      width: 100%;
      text-align: center;
    }
    p {
      font-family: inherit;
      color: $secondary-color;
      &.error {
        color: red;
        label {
          color: red;
        }
      }
    }
    .input-box {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      label {
        color: $secondary-color;
        &.error-label {
          color: red;
        }
      }
      input,
      select {
        padding: 10px;
        border: 1px solid $secondary-color;
        border-radius: 4px;
        &.error {
          border: 1px solid red;
        }
      }
      .error-msg {
        font-size: 13px;
        position: absolute;
        bottom: -16px;
        color: red;
      }
    }
    .check-box-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .check-box {
        display: flex;
        align-items: center;
        gap: 5px;
        margin: 0 30px 10px 0;
        label {
          color: $secondary-color;
        }
      }
    }
    .inline-check-container {
      display: inline-block;
      label {
        margin-left: 5px;
        color: $secondary-color;
      }
    }
    button {
      padding: 10px;
      background: $primary-color;
      color: white;
      border: 1px solid $primary-color;
      border-radius: 8px;
      cursor: pointer;
      max-width: 300px;
      width: 100%;
      font-size: 18px;
      &:hover {
        background: darken($primary-color, 10%);
      }
    }
  }
}

@media (max-width: 700px) {
  .container {
    .thank-you-message {
      flex-direction: column;
      justify-content: center;
      gap: 5px;
      .box {
        h1 {
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .container {
    .row {
      flex-direction: column;
      &.center {
        align-items: center;
      }
    }
  }
}
</style>
